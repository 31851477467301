<template>
  <div class="grid" style="padding-top: 20px">
    <div class="col-12 xl:col-12 sm:col-12 .col-12">
      <h3
        style="
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 140.62%;
          color: rgba(0, 0, 0, 0.7);
        "
      >
        Benefícios Secundários
      </h3>
      <h4
        style="
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          color: #585857;
          text-align: justify;
        "
      >
        Nesta seção são apresentadas funções metabólicas que os micro-organismos
        podem executar e que fornecem algum benefício para a planta. Aqui, estão
        listados apenas os benefícios secundários que apresentaram no tratamento
        com o Produto uma diferença estatística significativa (p &lt; 0,05) em
        relação ao Controle. A lista completa com todos as funções metabólicas
        avaliadas se encontra na seção de anexos. Apenas espécies e gêneros com
        diferença estatística significativa (p &lt; 0,05) na frequência relativa
        são apresentados nos gráficos.
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
