<template>
  <div
    style="
      background: #ffffff;
      border: 3px solid #f5f6f7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 17px;
      padding-bottom: 15px;
    "
  >
    <div style="display: flex; justify-content: flex-end">
      <div class="image">
        <Image src="/images/locIcon.svg" alt="Image" width="25" />
      </div>
    </div>
    <div class="block" style="justify-content: center; margin-top: -8%">
      <h4 class="area">{{ result.name }}</h4>
      <span class="areaname">{{ result.location }}</span>
    </div>

    <div
      v-if="project.typeProject === '01'"
      class="grid"
      style="justify-content: center; margin-top: 20px"
    >
      <div
        style="padding-right: 20px"
        class="col-12 xl:col-5 md:col-5 lg:col-12 sm:col-12 .col-12"
      >
        <div style="display: flex; justify-content: center">
          <Image
            src="/images/logoVeg.svg"
            alt="Image"
            width="30"
            style="padding-right: 10px"
          />
          <span class="areatype">Vegetativo</span>
        </div>
        <div class="imageIcon">
          <Image
            v-if="result.Vegetativo == 0"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/communIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else-if="result.Vegetativo > 0"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/positiveIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/negativeIcon.svg"
            alt="Image"
            width="25"
          />
          <div
            v-if="result.Vegetativo == 0"
            style="width: 99px; height: 28px; background: #979797"
          >
            <p style="font-size: 9px">Sem alteração</p>
          </div>
          <div v-else>
            <p class="results">
              {{ result.Vegetativo }}
            </p>
          </div>
        </div>
      </div>
      <div
        style="padding-left: 20px"
        class="col-12 xl:col-5 md:col-5 lg:col-12 sm:col-12 .col-12"
      >
        <div style="display: flex; justify-content: center">
          <Image
            src="/images/florIcon.svg"
            alt="Image"
            width="30"
            style="padding-right: 10px"
          />
          <span class="areatype">Reprodutivo</span>
        </div>
        <div class="imageIcon">
          <Image
            v-if="result.Florescimento == 0"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/communIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else-if="result.Florescimento > 0"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/positiveIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/negativeIcon.svg"
            alt="Image"
            width="25"
          />
          <div
            v-if="result.Florescimento == 0"
            style="width: 99px; height: 28px; background: #979797"
          >
            <p style="font-size: 9px">Sem alteração</p>
          </div>
          <div v-else>
            <p class="results">{{ result.Florescimento }}x</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="grid" style="justify-content: center; margin-top: 20px">
      <div
        style="padding-right: 20px"
        class="col-12 xl:col-5 md:col-5 lg:col-12 sm:col-12 .col-12"
      >
        <div style="display: flex; justify-content: center">
          <Image
            src="/images/logoVeg.svg"
            alt="Image"
            width="30"
            style="padding-right: 10px"
          />
          <span class="areatype">Vegetativo</span>
        </div>
        <div class="imageIcon">
          <Image
            v-if="result.Vegetativo == 0"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/communIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else-if="result.Vegetativo > 0"
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/positiveIcon.svg"
            alt="Image"
            width="25"
          />

          <Image
            v-else
            style="place-self: center; padding-top: 10px; margin-right: 10px"
            src="/images/negativeIcon.svg"
            alt="Image"
            width="25"
          />
          <div
            v-if="result.Vegetativo == 0"
            style="width: 99px; height: 28px; background: #979797"
          >
            <p style="font-size: 9px">Sem alteração</p>
          </div>
          <div v-else>
            <p class="results">{{ result.Vegetativo }}x</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    console.log("data");
    console.log(this.result);
  },
  props: {
    result: Object,
    project: Object,
  },
};
</script>

<style>
.results {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 140.62%;
  color: rgba(0, 0, 0, 0.8);
}
</style>
