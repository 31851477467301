<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <ProjectInfo />
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div v-if="loading === true">
              <ProgressBar mode="indeterminate" style="height: 0.5em" />
            </div>
            <div v-if="changes">
              <div
                v-for="change in changes"
                :key="change"
                style="padding-bottom: 30px"
              >
                <Accordion :activeIndex="1">
                  <AccordionTab
                    style="
                      background: linear-gradient(
                        90deg,
                        #90a956 0%,
                        #405b01 98.79%
                      );
                      border-radius: 0px 0px 0px 20px;
                    "
                  >
                    <template
                      style="
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                      "
                      #header
                    >
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div
                          style="
                            align-self: flex-start;
                            padding-top: 10px;
                            padding-left: 30px;
                          "
                        >
                          <span style="font-weight: 600; font-size: 18px">{{
                            change.description.organism
                          }}</span>
                        </div>
                        <div
                          style="
                            display: flex;
                            place-items: center;
                            width: max-content;
                            margin-left: 20px;
                            padding-right: 30px;
                            padding-left: 30px;
                            background: #ffffff;
                            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                            border-radius: 17px;
                            align-self: flex-end;
                          "
                        >
                          <Image
                            v-if="change.description.variation > 0"
                            src="/images/positiveIcon.svg"
                            alt="Image"
                            width="30"
                            style="padding-top: 16px"
                          />
                          <Image
                            v-else
                            src="/images/negativeIcon.svg"
                            alt="Image"
                            width="30"
                            style="padding-top: 16px"
                          />
                          <p
                            style="
                              font-style: normal;
                              font-weight: 900;
                              font-size: 25px;
                              line-height: 47px;
                              color: black;
                            "
                          >
                            {{ change.description.variation }}x
                          </p>
                        </div>
                      </div>
                    </template>

                    <div class="grid">
                      <div
                        v-for="result in change.areaResults"
                        :key="result.name"
                        class="col-12 xl:col-4 md:col-4 lg:col-12 sm:col-12 xs:col-12"
                        style="padding-bottom: 30px"
                      >
                        <CardInfo :result="result" :project="project" />
                      </div>
                    </div>

                    <div v-for="chart in change.chartData" :key="chart">
                      <ChartError
                        :data="chart"
                        :description="change.description"
                        :filterType="'basic'"
                        :chartType="'basic'"
                        @filter="getData"
                      />
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/SecundaryBenefits/BodyHeader.vue";
import CardInfo from "@/components/SecundaryBenefits/CardInfo.vue";
import ChartError from "@/components/Generic/ChartError.vue";
import { useProjectsStore } from "@/store/projects";
import axios from "axios";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      i: 1,
      changes: [
        {
          description: {
            organism: "Controle de fungos fitopatogênicos",
            variation: 2.7,
          },

          areaResults: [
            {
              id: 1,
              name: "Área 1",
              location: "Londrina, PR",
              Vegetativo: 2.2,
              Florescimento: 1.2,
            },
            {
              id: 2,
              name: "Área 2",
              location: "Arapongas, PR",
              Vegetativo: 2.7,
              Florescimento: 1.2,
            },
            {
              id: 3,
              name: "Área 3",
              location: "Maringa, PR",
              Vegetativo: 0.0,
              Florescimento: 0.0,
            },
          ],

          chartData: [
            {
              "Controle de fungos fitopatogênicos": {
                bacterias: [
                  {
                    type: "Bacillus",
                    avgH: 3.1,
                    avgLowT: 11.9,
                    avgHighT: 21.7,
                    avgT: 1.5,
                  },
                  {
                    type: "Paenibacillus",
                    avgH: 9.0,
                    avgLowT: 9.8,
                    avgHighT: 19.6,
                    avgT: 5,
                  },
                  {
                    type: "Streptomyces",
                    avgH: 3,
                    avgLowT: 10.6,
                    avgHighT: 20.4,
                    avgT: 0.9,
                  },
                  {
                    type: "Purpureocillium",
                    avgH: 4.5,
                    avgLowT: 10.6,
                    avgHighT: 20.4,
                    avgT: 0.9,
                  },
                ],
                fungus: [
                  {
                    type: "Trichoderma",
                    avgH: 6.2,
                    avgLowT: 9.1,
                    avgHighT: 19.1,
                    avgT: 5.5,
                  }
                ],
              },
            },
          ],
        },

        {
          description: {
            organism: "Fixação do nitrogênio",
            variation: 5.3,
          },

          areaResults: [
            {
              id: 1,
              name: "Área 1",
              location: "Londrina, PR",
              Vegetativo: 5.2,
              Florescimento: 5.3,
            },
            {
              id: 2,
              name: "Área 2",
              location: "Arapongas, PR",
              Vegetativo: 5.3,
              Florescimento: 4.7,
            },
            {
              id: 3,
              name: "Área 3",
              location: "Maringá, PR",
              Vegetativo: 0.0,
              Florescimento: 0.0,
            },
          ],

          chartData: [
            {
              "Fixação do nitrogênio": {
                bacterias: [
                  {
                    type: "Azospirillum brasiliense",
                    avgH: 5.2,
                    avgLowT: 9.1,
                    avgHighT: 19.1,
                    avgT: 0,
                  },
                  {
                    type: "Azospirillum genomospecies",
                    avgH: 2.1,
                    avgLowT: 9.8,
                    avgHighT: 19.6,
                    avgT: 0.5,
                  },
                  {
                    type: "Rhizobium oryzae",
                    avgH: 2.5,
                    avgLowT: 9.8,
                    avgHighT: 19.6,
                    avgT: 0,
                  },
                  {
                    type: "Bradyrhizobium",
                    avgH: 5.5,
                    avgLowT: 9.8,
                    avgHighT: 19.6,
                    avgT: 5,
                  },
                ],
                fungus: [],
              },
            },
          ],
        },

        {
          description: {
            organism: "Solubilização do fósforo",
            variation: 5.2,
          },

          areaResults: [
            {
              id: 1,
              name: "Área 1",
              location: "Londrina, PR",
              Vegetativo: 4.9,
              Florescimento: 4.2,
            },
            {
              id: 2,
              name: "Área 2",
              location: "Arapongas, PR",
              Vegetativo: 5.2,
              Florescimento: 4.3,
            },
            {
              id: 3,
              name: "Área 3",
              location: "Maringá, PR",
              Vegetativo: 2.1,
              Florescimento: 1.2,
            },
          ],

          chartData: [
            {
              "Solubilização do fósforo": {
                bacterias: [
                  {
                    type: "Paenibacillus",
                    avgH: 9.6,
                    avgLowT: 10.6,
                    avgHighT: 20.4,
                    avgT: 5.5,
                  },
                  {
                    type: "Acetobacter",
                    avgH: 15.6,
                    avgLowT: 10.6,
                    avgHighT: 20.4,
                    avgT: 6.9,
                  },
                  {
                    type: "Bacillus",
                    avgH: 3.1,
                    avgLowT: 10.6,
                    avgHighT: 20.4,
                    avgT: 1.5,
                  },
                ],
                fungus: [
                  {
                    type: "Nigrosabulum globosum",
                    avgH: 4.1,
                    avgLowT: 9.1,
                    avgHighT: 19.1,
                    avgT: 3.0,
                  },
                  {
                    type: "Trichoderma",
                    avgH: 6.2,
                    avgLowT: 9.8,
                    avgHighT: 19.6,
                    avgT: 5.5,
                  },
                  {
                    type: "Glomus",
                    avgH: 4.2,
                    avgLowT: 10.6,
                    avgHighT: 20.4,
                    avgT: 2.1,
                  },
                ],
              },
            },
          ],
        },
      ],
      selectedType: null,
      loading: true,
      displayModal: false,
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {
    //this.getData({});
    this.loading = false;
  },
  beforeUnmount() {},
  created() {},
  methods: {
    async getData({ taxonomico = 0, area = 0, estagio = 0 }) {
      debugger;
      try {
        this.loading = true;
        const response = await axios
          .get(
            `https://dev.b4adashboard.com/api/b4proof/resultados/secondaryBenefits/94/1/1/${taxonomico}/${area}/${estagio}`
          )
          .then((response) => response.data);

        this.changes = response;
        console.log(this.changes);
      } catch (e) {
        alert(e.message || "Ocorreu um erro");
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ChartError: ChartError,
    CardInfo: CardInfo,
  },
};
</script>

<style></style>
